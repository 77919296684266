var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('groups-filters',{attrs:{"availableGuides":_vm.availableGuides,"availableYears":_vm.availableYears,"availableGroupTypes":_vm.availableGroupTypes},on:{"update":function($event){return _vm.filterGroups($event)}}}),_c('b-card',[_c('div',{staticClass:"custom-search d-flex justify-content-end "},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[(_vm.canViewBasedOnRole([_vm.Roles.ORGANIZATION_ADMIN.role, _vm.Roles.ORGANIZATION_COORDINATOR.role]))?_c('b-button',{staticClass:"ml-1",attrs:{"variant":"primary","to":{ name: 'group-create' }}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("Ajouter un groupe")])]):_vm._e()],1)])],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.filteredGroups,"search-options":{
        enabled: true,
        externalQuery: _vm.searchTerm,
      },"select-options":{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      },"pagination-options":{
        enabled: true,
        perPage: _vm.pageLength,
      },"styleClass":"vgt-table striped"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'name')?_c('span',{staticClass:"text-nowrap"},[_c('router-link',{staticClass:"text-nowrap",staticStyle:{"cursor":"pointer"},attrs:{"to":{ name: 'organisation-group', params: { id: props.row.id } },"tag":"span"}},[_c('b-badge',{attrs:{"variant":"light-secondary","pill":""}},[_c('b-avatar',{staticClass:"mr-1",attrs:{"src":props.row.images,"variant":_vm.avatarColors(_vm.flattenUUIDtoBase(props.row.id)),"icon":"people-fill","size":"md"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.name))])],1)],1)],1):(props.column.field === 'status')?_c('span',[_c('b-badge',{attrs:{"variant":_vm.statusVariant(props.row.status)}},[_vm._v(_vm._s(_vm.t(("groups.status." + (props.row.status)))))])],1):(props.column.field === 'saleStatus')?_c('span',[_c('b-badge',{attrs:{"variant":_vm.saleStatusVariant(props.row.saleStatus)}},[_vm._v(_vm._s(_vm.t(("groups.sale_status." + (props.row.saleStatus)))))])],1):(props.column.field === 'type')?_c('span',[_c('feather-icon',{staticClass:"mr-50",class:("text-" + (_vm.typeVariant(props.row.type))),attrs:{"icon":_vm.typeIcons(props.row.type),"size":"18"}}),_c('span',{staticClass:"align-text-top text-capitalize"},[_vm._v(_vm._s(_vm.typeLabel(props.row.type)))])],1):(props.column.field === 'guide')?_c('span',[(props.row.guide)?_c('span',{staticClass:"align-text-top text-capitalize"},[_vm._v(_vm._s(props.row.guide))]):_c('span',{staticClass:"align-text-top text-capitalize"},[_vm._v("Non accompagné")])]):(props.column.field === 'action')?_c('span',[(
              _vm.canViewBasedOnRole([
                _vm.Roles.ORGANIZATION_ADMIN.role,
                _vm.Roles.ORGANIZATION_COORDINATOR.role ])
            )?_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"20"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":{
                  name: 'organisation-group',
                  params: { id: props.row.id },
                  hash: '#details',
                }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Edit2Icon"}}),_c('span',[_vm._v("Modifier")])],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.cloneGroupInstance(props.row)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CopyIcon"}}),_c('span',[_vm._v("Dupliquer")])],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteGroupInstance(props.row)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"TrashIcon"}}),_c('span',[_vm._v("Supprimer")])],1)],1)],1):_c('span',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon float-left",attrs:{"variant":"flat-primary","to":{
                name: 'organisation-group',
                params: { id: props.row.id },
              }}},[_c('span',{staticClass:"text-nowrap"},[_c('feather-icon',{attrs:{"icon":"EyeIcon","size":"14"}}),_c('span',{staticClass:"ml-1"},[_vm._v("Consulter")])],1)])],1)]):_vm._e()]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v("Indiquer 1 à")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['5', '10', '20']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" des "+_vm._s(props.total)+" données ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('div',{staticClass:"vgt-center-align vgt-text-disabled"},[_vm._v(" Aucun résultat trouvé ")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }