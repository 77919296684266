<template>
  <b-card no-body>
    <b-card-body class="py-1 px-2">
      <b-row>
        <b-col class="mb-md-0 mb-2" cols="12" md="4">
          <b-form-group
            label="Terme de recherche"
            label-for="main-agency-admin-first-name"
          >
            <b-input-group>
              <b-form-input
                v-model="criteria.search"
                placeholder="Ville, guide, etc."
                type="search"
                @input="(val) => $emit('update', criteria)"
              />
              <b-input-group-append>
                <span class="input-group-text">
                  <b-button
                    @click="$emit('update', criteria)"
                    variant="link"
                    class="m-0 p-0"
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </span>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>Type de groupe</label>
          <v-select
            label="text"
            v-model="selectedType"
            :options="availableGroupTypes"
            :reduce="(text) => text.value"
            @input="updateType($event)"
          />
        </b-col>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>Mois de départ</label>
          <v-select
            multiple
            label="label"
            v-model="criteria.departureMonth"
            :options="departureMonths"
            @input="updateDepartureMonth($event)"
          />
        </b-col>
        <b-col
          v-show="!showAdvancedFilters"
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-button
            class="p-0"
            variant="link"
            @click="showAdvancedFilters = !showAdvancedFilters"
          >
            Recherche avancée
          </b-button>
        </b-col>
        <template v-if="showAdvancedFilters">
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>Statut du groupe</label>
            <v-select
              class="w-100"
              v-model="criteria.status"
              @input="(val) => updateGroup('status', val)"
              :options="groupStatus"
              label="text"
              :reduce="(text) => text.value"
            />
          </b-col>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>Accompagnateur</label>
            <v-select
              :options="availableGuides"
              v-model="selectedGuide"
              :reduce="(x) => x.text"
              label="text"
              @input="updateGuide($event)"
            />
          </b-col>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>Année de départ</label>
            <v-select
              :options="availableYears"
              v-model="criteria.departureYear"
              class="w-100"
              @input="(val) => updateGroup('departureYear', val)"
            />
          </b-col>
        </template>
      </b-row>
      <b-row v-if="showAdvancedFilters">
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button
            class="p-0 pt-1"
            variant="link"
            @click="showAdvancedFilters = !showAdvancedFilters"
          >
            Cacher la recherche avancée
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BCollapse,
  BButton,
  VBToggle,
  BButtonGroup,
  BInputGroup,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
} from "bootstrap-vue";
import vSelect from "vue-select";
import groupCreationConfig from "@core/data/groupCreationConfig.js";
import _ from "lodash";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BCollapse,
    BButton,
    BButtonGroup,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    availableGuides: {
      type: Array,
      required: true,
    },
    availableYears: {
      type: Array,
      required: true,
    },
    availableGroupTypes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showAdvancedFilters: false,
      criteria: {
        search: this.$route.query.search || "",
        type: this.$route.query.type || "all",
        guide: this.$route.query.guide || "",
        status: this.$route.query.status || "all_active",
        departureYear: this.$route.query.departureYear || "",
        departureMonth: "",
      },
      groupStatus: [
        { text: "Tous", value: "all" },
        { text: "Tous les groupes actifs", value: "all_active" },
        { text: "En préparation", value: "draft" },
        { text: "Publié", value: "published" },
        { text: "Archivé", value: "archived" },
        { text: "Terminé", value: "completed" },
        { text: "Annulé", value: "cancelled" },
      ],
    };
  },
  computed: {
    departureMonths() {
      return groupCreationConfig.departureMonths;
    },
    guide() {
      const selected = this.availableGuides.find(
        (x) => x.value === this.criteria.guide
      );
      return selected ? selected.text : "";
    },
    selectedGuide: {
      get() {
        const selected = this.availableGuides.find(
          (x) => x.value === this.criteria.guide
        );
        return selected ? selected.text : "";
      },
      set() {
        const selected = this.availableGuides.find(
          (x) => x.value === this.criteria.guide
        );
        return selected ? selected.text : "";
      },
    },
    selectedType: {
      get() {
        const selected = this.availableGroupTypes.find(
          (x) => x.value.toLowerCase() === this.criteria.type
        );
        return selected ? selected : "all";
      },
      set(value) {
        const selected = this.availableGroupTypes.find(
          (x) => x.value.toLowerCase() === this.criteria.type
        );
        return selected ? selected : "all";
      },
    },
  },
  methods: {
    updateDepartureMonth(event) {
      let values = "";
      if (event.length > 0) {
        let month = event.map((x) => x.slug);
        values = month.length > 1 ? month.join() : month;
      }

      this.criteria.departureMonth = event.length > 0 ? event : [];
      this.updateGroup("departureMonth", values);
    },
    updateGuide(event) {
      let guide = "";
      if (event) {
        guide = this.availableGuides.find((x) => x.text === event).value;
      }
      this.criteria.guide = guide;
      this.updateGroup("guide", guide);
    },
    updateType(value) {
      let type = value ? value.toLowerCase() : "";
      this.criteria.type = type;
      this.updateGroup("type", type);
    },
    updateGroup(request, value) {
      const url = new URL(window.location.href);
      const hasValidValue = value && value !== "all";

      if (hasValidValue) {
        url.searchParams.set(request, value);
      }

      if (!hasValidValue && url.searchParams.has(request)) {
        url.searchParams.delete(request);
      }

      window.history.pushState({}, "", url);
      this.$emit("update", this.criteria);
    },
  },
  created() {
    let monthQuery = this.$route.query.departureMonth;
    if (monthQuery) {
      let month = monthQuery.length > 1 ? monthQuery.split(",") : [monthQuery];
      let selectedMonth = this.departureMonths.filter((x) =>
        month.includes(x.slug)
      );
      this.criteria.departureMonth = selectedMonth;
    }
    this.$emit("update", this.criteria);
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
