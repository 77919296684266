<template>
  <div>
    <groups-filters
      :availableGuides="availableGuides"
      :availableYears="availableYears"
      :availableGroupTypes="availableGroupTypes"
      @update="filterGroups($event)"
    />
    <b-card>
      <!-- Top controls -->
      <div class="custom-search d-flex justify-content-end "  >
        <b-form-group>
          <div class="d-flex align-items-center">
            <b-button variant="primary" class="ml-1" :to="{ name: 'group-create' }" v-if="canViewBasedOnRole([Roles.ORGANIZATION_ADMIN.role, Roles.ORGANIZATION_COORDINATOR.role])" >
              <span class="text-nowrap">Ajouter un groupe</span>
            </b-button>
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="filteredGroups"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        styleClass="vgt-table striped"
      >
        <div slot="emptystate">
          <div class="vgt-center-align vgt-text-disabled">
            Aucun résultat trouvé
          </div>
        </div>
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.field === 'name'" class="text-nowrap">
            <router-link
              :to="{ name: 'organisation-group', params: { id: props.row.id } }"
              class="text-nowrap"
              style="cursor: pointer"
              tag="span"
            >
              <b-badge variant="light-secondary" pill>
                <b-avatar
                  :src="props.row.images"
                  :variant="avatarColors(flattenUUIDtoBase(props.row.id))"
                  icon="people-fill"
                  size="md"
                  class="mr-1"
                />
                <span class="text-nowrap">{{ props.row.name }}</span>
              </b-badge>
            </router-link>
          </span>

          <!-- Column: status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">{{
              t(`groups.status.${props.row.status}`)
            }}</b-badge>
          </span>

          <!-- Column: saleStatus -->
          <span v-else-if="props.column.field === 'saleStatus'">
            <b-badge :variant="saleStatusVariant(props.row.saleStatus)">{{
              t(`groups.sale_status.${props.row.saleStatus}`)
            }}</b-badge>
          </span>

          <!-- Column: type -->
          <span v-else-if="props.column.field === 'type'">
            <feather-icon
              :icon="typeIcons(props.row.type)"
              size="18"
              class="mr-50"
              :class="`text-${typeVariant(props.row.type)}`"
            />
            <span class="align-text-top text-capitalize">{{
              typeLabel(props.row.type)
            }}</span>
          </span>
          <span v-else-if="props.column.field === 'guide'">
            <span
              class="align-text-top text-capitalize"
              v-if="props.row.guide"
              >{{ props.row.guide }}</span
            >
            <span class="align-text-top text-capitalize" v-else
              >Non accompagné</span
            >
          </span>
          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span
              v-if="
                canViewBasedOnRole([
                  Roles.ORGANIZATION_ADMIN.role,
                  Roles.ORGANIZATION_COORDINATOR.role,
                ])
              "
            >
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="20"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <!--
                <b-dropdown-item :to="{ name: 'group-create', params: { id: props.row.id }}">
                  <feather-icon icon="Edit2Icon" class="mr-50"/>
                  <span>Continuer la création</span>
                </b-dropdown-item>
                -->
                <b-dropdown-item
                  :to="{
                    name: 'organisation-group',
                    params: { id: props.row.id },
                    hash: '#details',
                  }"
                >
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Modifier</span>
                </b-dropdown-item>
                <b-dropdown-item @click="cloneGroupInstance(props.row)">
                  <feather-icon icon="CopyIcon" class="mr-50" />
                  <span>Dupliquer</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteGroupInstance(props.row)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Supprimer</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
            <span v-else>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-primary"
                :to="{
                  name: 'organisation-group',
                  params: { id: props.row.id },
                }"
                class="btn-icon float-left"
              >
                <span class="text-nowrap">
                  <feather-icon icon="EyeIcon" size="14" />
                  <span class="ml-1">Consulter</span>
                </span>
              </b-button>
            </span>
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">Indiquer 1 à</span>
              <b-form-select
                v-model="pageLength"
                :options="['5', '10', '20']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> des {{ props.total }} données </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BRow,
  BCol,
  BCardBody,
  BCardHeader,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BIconPeopleFill,
  BIcon,
} from "bootstrap-vue";
import _ from "lodash";
import { VueGoodTable } from "vue-good-table";
import { onMounted, ref } from "@vue/composition-api";
import GroupsFilters from "./GroupsFilters.vue";
import groupCreationConfig from "@core/data/groupCreationConfig.js";
import { useGroupApi } from "@/modules/group/composables/use-group-api";
import { useToastNotification } from "@/shared/composables/use-toast-notifications";
import { useAvatarCapabilities } from "@/shared/composables/use-avatar-capabilities";
import dayjs from "dayjs";
import { useApplicationContext } from "@/shared/composables/use-application-context";
import {
  AUTH_STORE_NAMESPACE,
  AUTHENTICATED_USER_GETTER,
} from "@/modules/authnz/models/store";
import Roles from "@/modules/authnz/models/role";
import { useLocalisation } from "@/shared/composables/use-localisation";
import Ripple from "vue-ripple-directive";
import router from "@/router";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardBody,
    BCardHeader,
    VueGoodTable,
    BAvatar,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    GroupsFilters,
    BIcon,
    BIconPeopleFill,
  },
  setup(props, ctx) {
    const { getAllAvailableGroups, cloneGroup, deleteGroup } = useGroupApi();
    const { displaySuccessMessage, displayErrorMessage } =
      useToastNotification();
    const { store } = useApplicationContext();
    const { t } = useLocalisation();

    const currentAuthUser = ref({});
    currentAuthUser.value =
      store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`];

    const statusOptions = [
      { label: "inactive", value: "inactive" },
      { label: "pending", value: "pending" },
      { label: "open", value: "open" },
    ];

    let filtersSearch = {
      guide: null,
      groupStatus: null,
      groupType: null,
      departureYear: null,
      departureMonth: [],
    };

    const groups = ref([]);
    const filteredGroups = ref([]);

    const fetchGroups = async () => {
      try {
        groups.value = await getAllAvailableGroups();
        filteredGroups.value = groups.value;
      } catch {
        displayErrorMessage(
          "Une erreur est survenue en essayant de chercher les groupes disponibles"
        );
      }
    };

    const canViewBasedOnRole = (roles) => {
      try {
        let role = currentAuthUser.value.role.role;
        return roles.includes(role);
      } catch {
        return false;
      }
    };

    const { avatarColors, flattenUUIDtoBase } = useAvatarCapabilities();
    currentAuthUser.value =
      store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`];

    const filterGuide = function (guide) {
      filtersSearch.guide = guide;
      filterGroups();
    };

    const filterStatus = function (status) {
      filtersSearch.status = status;
      filterGroups();
    };

    const filterType = function (type) {
      filtersSearch.type = type;
      filterGroups();
    };

    const searchTerm = ref(ctx.root.$route.query.search || "");

    const filterGroups = function (criteria) {
      filteredGroups.value = groups.value;
      searchTerm.value = criteria.search || "";

      if (criteria.guide) {
        filteredGroups.value = filteredGroups.value.filter(
          (group) => _.kebabCase(group.guide) === _.kebabCase(criteria.guide)
        );
      }

      if (criteria.status && criteria.status !== "all") {
        filteredGroups.value = filteredGroups.value.filter((group) => {
          if (criteria.status === "all_active") {
            return ["published", "completed", "draft"].includes(
              group.status.toLowerCase()
            );
          } else {
            return group.status.toLowerCase() === criteria.status;
          }
        });
      }

      if (criteria.type && criteria.type !== "all") {
        filteredGroups.value = filteredGroups.value.filter(
          (group) => group.type.toLowerCase() === criteria.type
        );
      }

      if (criteria.departureMonth.length > 0) {
        const months = criteria.departureMonth.map((x) => parseInt(x.value));
        filteredGroups.value = filteredGroups.value.filter((group) =>
          months.includes(dayjs(group.departureDate).month())
        );
      }

      if (criteria.departureYear) {
        filteredGroups.value = filteredGroups.value.filter(
          (group) => dayjs(group.departureDate).year() == criteria.departureYear
        );
      }
    };

    const getDepartureMonthQuery = function () {
      let query = ctx.root.$route.query.departureMonth;
      if (query) {
        let month = query.split(",");
        return groupCreationConfig.departureMonths.filter((x) =>
          month.includes(x.slug)
        );
      }
      return "";
    };

    onMounted(async () => {
      await fetchGroups();

      await filterGroups({
        search: ctx.root.$route.query.search || "",
        type: ctx.root.$route.query.type || "all",
        guide: ctx.root.$route.query.guide || "",
        status: ctx.root.$route.query.status || "all_active",
        departureYear: ctx.root.$route.query.departureYear || "",
        departureMonth: getDepartureMonthQuery(),
      });
    });

    return {
      searchTerm,
      statusOptions,
      groups,
      fetchGroups,
      filteredGroups,
      filterGuide,
      filterStatus,
      filterType,
      avatarColors,
      flattenUUIDtoBase,
      filterGroups,
      filtersSearch,
      canViewBasedOnRole,
      Roles,
      cloneGroup,
      deleteGroup,
      displaySuccessMessage,
      displayErrorMessage,
      t,
    };
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      columns: [
        {
          label: "Statut",
          field: "status",
        },
        {
          label: "Nom du groupe",
          field: "name",
        },
        {
          label: "Statut de vente",
          field: "saleStatus",
        },
        {
          label: "Départ",
          field: "departureDate",
        },
        {
          label: "Accompagnateur",
          field: "guide",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        CANCELLED: "light-danger",
        COMPLETED: "light-secondary",
        DRAFT: "light-info",
        PUBLISHED: "light-success",
        ARCHIVED: "light-warning",
      };
      return (status) => statusColor[status];
    },

    statusLabel() {
      const statusLabel = {
        CANCELLED: "CANCELLED",
        COMPLETED: "COMPLETED",
        DRAFT: "DRAFT",
        PUBLISHED: "PUBLISHED",
        ARCHIVED: "ARCHIVED",
      };
      return (status) => statusLabel[status];
    },
    saleStatusVariant() {
      const salesStatusColor = {
        UPCOMING: "light-danger",
        ON_SALE: "light-secondary",
        ON_DEMAND: "light-info",
        FULL: "light-success",
        CLOSE: "light-warning",
      };
      return (status) => salesStatusColor[status];
    },

    saleStatusLabel() {
      const salesStatusLabel = {
        UPCOMING: "UPCOMING",
        ON_SALE: "ON_SALE",
        ON_DEMAND: "ON_DEMAND",
        FULL: "FULL",
        CLOSE: "CLOSE",
      };
      return (status) => salesStatusLabel[status];
    },

    typeVariant() {
      const typeColor = {
        TRANSPORT: "danger",
        all_inclusive: "success",
      };
      return (type) => typeColor[type];
    },

    typeLabel() {
      const typeLabel = {
        TRANSPORT: "Transport",
        all_inclusive: "All Inclusive",
      };
      return (type) => typeLabel[type];
    },

    typeIcons() {
      const typeIcons = {
        TRANSPORT: "DropletIcon",
        all_inclusive: "SunIcon",
      };
      return (type) => typeIcons[type];
    },

    availableGuides() {
      let guides = this.groups
        .filter((x) => x.guide !== undefined && x.guide !== null)
        .map((x) => {
          return {
            value: _.kebabCase(x.guide),
            text: x.guide,
          };
        })
        .sort();

      let uniqueGuides = [...new Set(guides)];
      return uniqueGuides;
    },
    availableYears() {
      return this.groups
        .map((x) => dayjs(x.departureDate).year())
        .filter((item, i, ar) => ar.indexOf(item) === i)
        .sort();
    },
    availableGroupTypes() {
      const baseOptions = [{ value: "all", text: "Tous" }];
      return [...baseOptions, ...groupCreationConfig.type];
    },
  },
  methods: {
    async cloneGroupInstance(group) {
      try {
        const response = await this.cloneGroup(group.id);
        let matches = response.headers.location.split("/");
        const clonedGroupId = matches[5];

        this.$router.push({ path: `/groups/${clonedGroupId}` });

        this.displaySuccessMessage("Le groupe a été dupliqué avec succès!");
      } catch (e) {
        this.displayErrorMessage(
          "Une erreur s'est produite lors de la duplication du groupe."
        );
      }
    },
    async deleteGroupInstance(group) {
      try {
        this.$swal({
          title: "Suppression du groupe",
          text: "Désirez-vous vraiment supprimer ce groupe?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Oui",
          cancelButtonText: "Annuler",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.value) {
            await this.deleteGroup(group.id);

            this.fetchGroups();
            this.displaySuccessMessage("Groupe supprimé");
          }
        });
      } catch (e) {
        this.displayErrorMessage(
          "Une erreur s'est produite lors de la suppression du groupe."
        );
      }
    },
  },
  watch: {
    searchTerm: _.debounce(function (newValue) {
      const url = new URL(window.location.href);
      if (!newValue) {
        url.searchParams.delete("search");
      }
      if (newValue != this.$route.query.search) {
        url.searchParams.set("search", newValue);
      }
      window.history.pushState({}, "", url);
    }, 300),
    "$route.query.search": {
      handler: function (value) {
        if (value == undefined) {
          value = "";
        }

        if (this.searchTerm != value) {
          this.searchTerm = value || "";
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
