export const useAvatarCapabilities = () => {
  const flattenUUIDtoBase = (id) => {
    const UUID_BASE = 16;
    const BASE = 2;
    return Math.round(parseInt(id.slice(-1), UUID_BASE) / BASE);
  };

  const avatarColors = (selectedColor) => {
    const avatarColors = {
      "1": "primary",
      "2": "dark",
      "3": "info",
      "4": "success",
      "5": "danger",
      "6": "warning",
      "7": "info",
      "8": "secondary",
    };

    return avatarColors[selectedColor];
  };

  return {
    flattenUUIDtoBase,
    avatarColors,
  };
};
